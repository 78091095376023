import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardContent,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import RefreshIcon from '@mui/icons-material/Refresh';
import { collection, query, where, getDocs, doc, deleteDoc, getDoc, updateDoc, onSnapshot, addDoc} from 'firebase/firestore';
import { db, auth } from '../firebaseConfig'; // Adjust this import path as needed
import BookGuest from './BookGuest';
import WaitlistModal from './WaitlistModal';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue'; 
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import axios from 'axios';


const WeeklyBookings = ({defaultDay, defaultTime, defaultWeek}) => {
  const adminPhoneNumbers = [
    "+447725868605",
    "+447725868606",
    "+447989952253",
    "+447740196104"
  ]

  const [refreshing, setRefreshing] = useState(false);
  const refreshTimeoutRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [waitlistCount, setWaitlistCount] = useState(0);
  const [selectedDay, setSelectedDay] = useState(0);

  const [notificationStatus, setNotificationStatus] = useState(false);
  const [checkingNotification, setCheckingNotification] = useState(true);

  //if day is set (e.g. Mon, Tues etc), set selectedDay to that day as the index:
  
  const [timeOfDay, setTimeOfDay] = useState('morning');
  const [loading, setLoading] = useState(true);
  const [weekOptions, setWeekOptions] = useState([]);
  const [selectedWeekString, setSelectedWeekString] = useState('');
  const [isBookGuestOpen, setIsBookGuestOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [spotToFreeUp, setSpotToFreeUp] = useState(null);

  const [bookings, setBookings] = useState({
    members: {
      0: { morning: [], afternoon: [] },
      1: { morning: [], afternoon: [] },
      2: { morning: [], afternoon: [] },
      3: { morning: [], afternoon: [] },
      4: { morning: [], afternoon: [] },
    },
    guests: {
      0: { morning: [], afternoon: [] },
      1: { morning: [], afternoon: [] },
      2: { morning: [], afternoon: [] },
      3: { morning: [], afternoon: [] },
      4: { morning: [], afternoon: [] },
    }
  });

  const MAX_SLOTS = 35; // Maximum number of slots per session

  const [freeUpModalOpen, setFreeUpModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [guestToDelete, setGuestToDelete] = useState(null);

  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false);
  const handleWaitlistClick = () => {
    setIsWaitlistModalOpen(true);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("user", user);
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  //if defaultDay, defaultTime, defaultWeek update the default values:
    useEffect(() => {
    if (defaultDay !== null) {
        setSelectedDay(defaultDay);
    }
    if (defaultTime !== null) {
        setTimeOfDay(defaultTime);
    }
    if (defaultWeek) {
        setSelectedWeekString(defaultWeek);
    }
    }, [defaultDay, defaultTime, defaultWeek]);


    useEffect(() => {
      const checkWaitlistCount = async () => {
        if (!selectedWeekString || selectedDay === null || !timeOfDay) return;
        
        try {
          const notificationsRef = collection(db, 'waitlist');
          const q = query(
            notificationsRef,
            where('weekStart', '==', selectedWeekString),
            where('day', '==', selectedDay),
            where('timeOfDay', '==', timeOfDay)
          );
          
          const querySnapshot = await getDocs(q);
          setWaitlistCount(querySnapshot.size);
        } catch (error) {
          console.error('Error checking waitlist count:', error);
          setWaitlistCount(0);
        }
      };
    
      checkWaitlistCount();
    }, [selectedWeekString, selectedDay, timeOfDay]);

  const getWeekStart = (date) => {
    const d = new Date(date);
    d.setDate(d.getDate() - d.getDay() + 1); // Set to Monday of the week
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  };

  const formatDateStringSunday = (date) => {
    
    //ensure date is the last sunday:
    date.setDate(date.getDate() - 1);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');



    console.log(`${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  };

  const formatDateForDisplay = (date) => {
    const startDate = new Date(date);
    //add one day to startDate:
    startDate.setDate(startDate.getDate() + 1);
    const endDate = new Date(startDate);

    endDate.setDate(endDate.getDate() + 4);
  
    const startMonth = startDate.toLocaleDateString('en-US', { month: 'short' });
    const endMonth = endDate.toLocaleDateString('en-US', { month: 'short' });
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
  
    if (startMonth === endMonth) {
      return `${startMonth} ${startDay}-${endDay}`;
    } else {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
    }
  };

  const handleOpenBookGuest = (schedule) => {
    setSelectedSchedule(schedule);
    setIsBookGuestOpen(true);
  };

  const handleManualRefresh = () => {
    if (!refreshing) {
      setRefreshing(true);
      refreshWeekOptions();
      fetchBookings(selectedWeekString);
    }
  };

  const handleCloseBookGuest = () => {
    setIsBookGuestOpen(false);
    setSelectedSchedule(null);
    fetchBookings(selectedWeekString);
  };

  useEffect(() => {
    refreshWeekOptions();
  }, []);

  useEffect(() => {
    const checkNotificationStatus = async () => {
      if (!currentUser || !selectedWeekString || selectedDay === null || !timeOfDay) return;
      
      setCheckingNotification(true);
      try {
        const notificationsRef = collection(db, 'waitlist');
        const q = query(
          notificationsRef,
          where('userId', '==', currentUser.uid),
          where('weekStart', '==', selectedWeekString),
          where('day', '==', selectedDay),
          where('timeOfDay', '==', timeOfDay)
        );
        
        const querySnapshot = await getDocs(q);
        setNotificationStatus(!querySnapshot.empty);
      } catch (error) {
        console.error('Error checking notification status:', error);
      }
      setCheckingNotification(false);
    };
  
    checkNotificationStatus();
  }, [currentUser, selectedWeekString, selectedDay, timeOfDay]);


  //write a function that updates the weekOptions when the week changes (called on refresh):
  const refreshWeekOptions = () => {
    const today = new Date();
    if (selectedWeekString){
      //if selectedWeekString is in the start of last week, refresh:
      if (getWeekStart(new Date(selectedWeekString)) < getWeekStart(today)){
        //week is in the past, continue - refresh the list...
      }
      else{
        return;
      }
    }

    const thisWeekStart = getWeekStart(today);
    const nextWeekStart = new Date(thisWeekStart);
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);

    const thisWeekString = formatDateStringSunday(thisWeekStart);
    const nextWeekString = formatDateStringSunday(nextWeekStart);

    setWeekOptions([
      { label: `This week (${formatDateForDisplay(thisWeekStart)})`, value: thisWeekString },
      { label: `Next week (${formatDateForDisplay(nextWeekStart)})`, value: nextWeekString }
    ]);
    
    setSelectedWeekString(thisWeekString);

    fetchBookings(thisWeekString)
  }

  const fetchBookings = useCallback((weekStart) => {
    setLoading(true);
    setRefreshing(true);
  
    let newBookings = {
      members: {},
      guests: {},
      notAttending: {}
    };
  
    // Initialize the structure for each day and time
    for (let i = 0; i < 5; i++) {
      newBookings.members[i] = { morning: [], afternoon: [] };
      newBookings.guests[i] = { morning: [], afternoon: [] };
      newBookings.notAttending[i] = { morning: [], afternoon: [] };
    }
  
    // Fetch all active members
    const membersQuery = query(collection(db, 'members'), where('status', '==', 'Active'));
    const unsubscribeMembers = onSnapshot(membersQuery, (membersSnapshot) => {

      setLoading(true);
      // Reset members and notAttending data for each snapshot
      for (let i = 0; i < 5; i++) {
        newBookings.members[i] = { morning: [], afternoon: [] };
        newBookings.notAttending[i] = { morning: [], afternoon: [] };
      }
  
      membersSnapshot.forEach((doc) => {
        const member = doc.data();
        const customSchedule = member.customSchedules?.find(
          schedule => schedule.weekStart === weekStart
        );
        const scheduleToUse = customSchedule ? customSchedule.schedule : (member.defaultSchedule || {});
  
        Object.keys(scheduleToUse).forEach((day) => {
          ['morning', 'afternoon'].forEach((timeOfDay) => {
            if (scheduleToUse[day][timeOfDay]) {
              newBookings.members[day][timeOfDay].push({
                id: doc.id,
                userId: member.userId,
                name: member.childName
              });
            } else if (member.defaultSchedule[day][timeOfDay] && !scheduleToUse[day][timeOfDay]) {
              newBookings.notAttending[day][timeOfDay].push({
                id: doc.id,
                userId: member.userId,
                name: member.childName
              });
            }
          });
        });
      });
  
      setBookings(prevBookings => ({
        ...prevBookings,
        members: newBookings.members,
        notAttending: newBookings.notAttending
      }));
      setLoading(false);
      setRefreshing(false);
    });
  
    // Fetch guests for the specific week
    const guestsQuery = query(
      collection(db, 'guests'),
      where('status', '==', 'Active'),
      where('schedule.weekStart', '==', weekStart)
    );
    const unsubscribeGuests = onSnapshot(guestsQuery, (guestsSnapshot) => {
      setLoading(true);
      // Reset guests data for each snapshot
      for (let i = 0; i < 5; i++) {
        newBookings.guests[i] = { morning: [], afternoon: [] };
      }
  
      guestsSnapshot.forEach((doc) => {
        const guest = doc.data();
        const { schedule } = guest;
        const day = schedule.day;
        const timeOfDay = schedule.timeOfDay;
  
        if (newBookings.guests[day] && newBookings.guests[day][timeOfDay]) {
          newBookings.guests[day][timeOfDay].push({
            id: doc.id,
            name: guest.guestName,
            userId: guest.userId,
            bookingNote: guest.bookingNote
          });
        }
      });
  
      setBookings(prevBookings => ({
        ...prevBookings,
        guests: newBookings.guests
      }));
      setLoading(false);
      setRefreshing(false);
    });
  
    return () => {
      unsubscribeMembers();
      unsubscribeGuests();
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (selectedWeekString) {
      unsubscribe = fetchBookings(selectedWeekString);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
   
  }, [selectedWeekString, fetchBookings]);

  useEffect(() => {
    const startAutoRefresh = () => {
      refreshTimeoutRef.current = setTimeout(() => {
        refreshWeekOptions();
        //fetchBookings(selectedWeekString);
        startAutoRefresh();
      }, 60000);
    };

    startAutoRefresh();

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [fetchBookings, selectedWeekString]);

  /*useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50 && !refreshing) {
          handleRefresh();
        }
      }
    };

    const currentScrollContainer = scrollContainerRef.current;
    if (currentScrollContainer) {
      currentScrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentScrollContainer) {
        currentScrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [refreshing]);

  const handleRefresh = () => {
    if (!refreshing) {
      setRefreshing(true);
      refreshWeekOptions();
      fetchBookings(selectedWeekString);
    }
  };*/

  const handleDayChange = (event, newValue) => {
    setSelectedDay(newValue);
  };

  const handleTimeChange = (event, newTime) => {
    if (newTime !== null) {
      setTimeOfDay(newTime);
    }
  };

  const handleWeekChange = (event) => {
    setSelectedWeekString(event.target.value);
  };

  const handleSave = () => {
    console.log('Saving bookings...');
    // Implement save logic here if needed
  };

  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

  const getNotAttendingForSelectedDayAndTime = () => {
    return bookings.notAttending[selectedDay]?.[timeOfDay] || [];
  };

  const getBookingsForSelectedDayAndTime = () => {
    const bookingsForDay = bookings.members[selectedDay]?.[timeOfDay] || [];
    
    // Sort the bookings
    return bookingsForDay.sort((a, b) => {
      // Current user's bookings come first
      if (a.userId === currentUser?.uid && b.userId !== currentUser?.uid) return -1;
      if (b.userId === currentUser?.uid && a.userId !== currentUser?.uid) return 1;
      
      // Then sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
  };

  const getGuestsForSelectedDayAndTime = () => {
    const guestsForDay = bookings.guests[selectedDay]?.[timeOfDay] || [];
    
    // Sort the guests
    return guestsForDay.sort((a, b) => {
      // Current user's guests come first
      if (a.userId === currentUser?.uid && b.userId !== currentUser?.uid) return -1;
      if (b.userId === currentUser?.uid && a.userId !== currentUser?.uid) return 1;
      
      // Then sort alphabetically by name
      return a.name.localeCompare(b.name);
    });
  };

  const getFreeSpots = () => {
    const bookedSpots = getBookingsForSelectedDayAndTime().length + getGuestsForSelectedDayAndTime().length;
    const freeSpots = MAX_SLOTS - bookedSpots;
    return freeSpots;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const removeFromWaitlist = async (userId, weekStart, day, timeOfDay) => {
    try {
      const waitlistRef = collection(db, 'waitlist');
      const q = query(
        waitlistRef,
        where('userId', '==', userId),
        where('weekStart', '==', weekStart),
        where('day', '==', day),
        where('timeOfDay', '==', timeOfDay)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Remove the waitlist entry
        await deleteDoc(querySnapshot.docs[0].ref);
        // Update the waitlist count
        setWaitlistCount(prevCount => Math.max(0, prevCount - 1));
        setNotificationStatus(false);
      }
    } catch (error) {
      console.error('Error removing from waitlist:', error);
    }
  };

  const onBookingMade = async () => {
    // Remove user from waitlist if they were on it
    if (currentUser) {
      await removeFromWaitlist(
        currentUser.uid,
        selectedWeekString,
        selectedDay,
        timeOfDay
      );
    }

    setSnackbar({
      open: true,
      message: 'Booking successful!',
      severity: 'success'
    });
    fetchBookings(selectedWeekString);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleFreeUpSlotClick = (day, time, person) => {
    //display confirmation modal to free up slot:

    setSpotToFreeUp({ day: day, timeOfDay: time, memberId: person.id, person: person });
    setFreeUpModalOpen(true);
    console.log(`Open slot clicked for ${person.id} on ${day} ${time}`);
  };


  const handleFreeUpConfirm = async () => {
    if (!spotToFreeUp) return;
  
    try {
      const memberRef = doc(db, 'members', spotToFreeUp.memberId);
      const memberDoc = await getDoc(memberRef);
      
      if (!memberDoc.exists()) {
        throw new Error('Member document not found');
      }
  
      const memberData = memberDoc.data();
      const customSchedules = memberData.customSchedules || [];
  
      // Find existing custom schedule for the selected week
      const existingCustomScheduleIndex = customSchedules.findIndex(
        schedule => schedule.weekStart === selectedWeekString
      );
  
      let updatedCustomSchedules;
  
      if (existingCustomScheduleIndex !== -1) {
        // Merge with existing custom schedule
        updatedCustomSchedules = [...customSchedules];
        updatedCustomSchedules[existingCustomScheduleIndex] = {
          ...updatedCustomSchedules[existingCustomScheduleIndex],
          schedule: {
            ...updatedCustomSchedules[existingCustomScheduleIndex].schedule,
            [spotToFreeUp.day]: {
              ...updatedCustomSchedules[existingCustomScheduleIndex].schedule[spotToFreeUp.day],
              [spotToFreeUp.timeOfDay]: false
            }
          }
        };
      } else {
        // Create new custom schedule
        const newCustomSchedule = {
          weekStart: selectedWeekString,
          schedule: {
            ...memberData.defaultSchedule,
            [spotToFreeUp.day]: {
              ...memberData.defaultSchedule[spotToFreeUp.day],
              [spotToFreeUp.timeOfDay]: false
            }
          }
        };
        updatedCustomSchedules = [...customSchedules, newCustomSchedule];
      }
  
      // Update the member's custom schedules
      await updateDoc(memberRef, {
        customSchedules: updatedCustomSchedules,
        updatedDate: new Date()
      });

       // Call notifyWaitlist function
      await notifyWaitlist(spotToFreeUp.day, spotToFreeUp.timeOfDay, selectedWeekString);

  
      setFreeUpModalOpen(false);
      setSpotToFreeUp(null);
      
      fetchBookings(selectedWeekString);
    } catch (error) {
      console.error("Error freeing up spot:", error);
      setSnackbar({
        open: true,
        message: 'Failed to free up spot. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleFreeUpCancel = () => {
    setFreeUpModalOpen(false);
    setSpotToFreeUp(null);
  };

  const formatDate = (weekStart, dayIndex) => {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + dayIndex + 1);
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
  };

  const formatDateShort = (weekStart, dayIndex) => {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + dayIndex + 1);
    return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
  };  

  const notifyWaitlist = async (day, timeOfDay, weekStart) => {
    try {
      // Get all users on the waitlist for this specific slot
      const waitlistRef = collection(db, 'waitlist');
      const waitlistQuery = query(
        waitlistRef,
        where('day', '==', day),
        where('timeOfDay', '==', timeOfDay),
        where('weekStart', '==', weekStart)
      );
      
      const waitlistSnapshot = await getDocs(waitlistQuery);
      
      if (waitlistSnapshot.empty) {
        console.log('No users on waitlist for this slot');
        return;
      }

      // Sort waitlist entries by creation date
      const waitlistEntries = waitlistSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));

      const sortedWaitlist = waitlistEntries.sort((a, b) => 
        (a.createdAt && b.createdAt) ? a.createdAt - b.createdAt : 0
      );

      // Get the first person in line
      const firstInLine = sortedWaitlist[0];
      
      // Get their user details
      const userDoc = await getDoc(doc(db, 'users', firstInLine.userId));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        
        if (userData.userPhone) {
          try {
            // Send notification to only the first person
            await axios.post('/api/send-sms', {
              to: userData.userPhone,
              message: `A spot is open for the ${timeOfDay} of ${formatDate(weekStart, day)}! You're next in line. Book now or leave the waitlist: https://herts.coach`
            });

            setSnackbar({
              open: true,
              message: 'Notified the first person on the waitlist',
              severity: 'success'
            });
          } catch (error) {
            console.error(`Failed to send notification:`, error);
            setSnackbar({
              open: true,
              message: 'Your spot has been released',
              severity: 'success'
            });
          }
        } else {
          setSnackbar({
            open: true,
            message: 'Your spot has been released',
            severity: 'success'
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: 'Your spot has been released',
          severity: 'success'
        });
      }

    } catch (error) {
      console.error('Error in notifyWaitlist:', error);
      setSnackbar({
        open: true,
        message: 'Your spot has been released',
        severity: 'success'
      });
    }
  };

  // Add this function to handle the toggle
  const handleNotificationToggle = async () => {
    if (!currentUser) {
      setSnackbar({
        open: true,
        message: 'Please sign in to set notifications',
        severity: 'warning'
      });
      return;
    }
  
    try {
      const notificationsRef = collection(db, 'waitlist');
      const q = query(
        notificationsRef,
        where('userId', '==', currentUser.uid),
        where('weekStart', '==', selectedWeekString),
        where('day', '==', selectedDay),
        where('timeOfDay', '==', timeOfDay)
      );
  
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        // Add notification
        await addDoc(notificationsRef, {
          userId: currentUser.uid,
          weekStart: selectedWeekString,
          day: selectedDay,
          timeOfDay: timeOfDay,
          createdAt: new Date()
        });
        setNotificationStatus(true);
        // Increment waitlist count
        setWaitlistCount(prevCount => prevCount + 1);
        setSnackbar({
          open: true,
          message: 'We\'ll notify you when a spot opens up.',
          severity: 'success'
        });
      } else {
        // Remove notification
        const docToDelete = querySnapshot.docs[0];
        await deleteDoc(docToDelete.ref);
        setNotificationStatus(false);
        // Decrement waitlist count
        setWaitlistCount(prevCount => Math.max(0, prevCount - 1));
        setSnackbar({
          open: true,
          message: 'Removed from waitlist',
          severity: 'info'
        });
      }
    } catch (error) {
      console.error('Error toggling notification:', error);
      setSnackbar({
        open: true,
        message: 'Error setting notification. Please try again.',
        severity: 'error'
      });
    }
  };


  const handleDeleteClick = (guest) => {
    setGuestToDelete(guest);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!guestToDelete) return;

    try {
      await deleteDoc(doc(db, 'guests', guestToDelete.id));
      setDeleteDialogOpen(false);
      setGuestToDelete(null);
      setSnackbar({
        open: true,
        message: 'Guest deleted successfully',
        severity: 'success'
      });
      fetchBookings(selectedWeekString);
    } catch (error) {
      console.error("Error deleting guest:", error);
      setSnackbar({
        open: true,
        message: 'Failed to delete guest. Please try again.',
        severity: 'error'
      });
    }
  };

  const dayIsNotInThePast = (dayIndex) => {
    const today = new Date();

    //today at midnight:
    today.setHours(0, 0, 0, 0);

    const dayToCheck = new Date(selectedWeekString);
    dayToCheck.setDate(dayToCheck.getDate() + dayIndex + 1);
    return dayToCheck >= today;
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setGuestToDelete(null);
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      pt: "25px",
      pb: "50px",
      minHeight: '100vh', // Changed from height to minHeight
      bgcolor: '#f5f5f5',
      position: 'relative', // Added position relative
      zIndex: 0 // Added a base z-index
    }}
    ref={scrollContainerRef}
    >
       {refreshing && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
      <Card sx={{ maxWidth: 400, width: '100%', m: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" component="h2">
                Weekly Bookings
              </Typography>
              <IconButton 
                onClick={handleManualRefresh} 
                disabled={refreshing}
                aria-label="refresh"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Week</InputLabel>
            <Select
              value={selectedWeekString}
              label="Select Week"
              onChange={handleWeekChange}
            >
              {weekOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                   {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            <Tabs 
              value={selectedDay} 
              onChange={handleDayChange} 
              aria-label="weekday tabs" 
              variant="fullWidth"
              sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-between',
                },
                '& .MuiTab-root': {
                  minWidth: 'auto',
                  padding: '6px 2px',
                  fontSize: '0.8rem',
                },
              }}
            >
              {days.map((day, index) => (
                
                <Tab label={day} key={index} sx={dayIsNotInThePast(index)? {fontWeight: "500"}: {}}  disabled={!dayIsNotInThePast(index)}/>
              ))}
            </Tabs>
          </Box>

          <ToggleButtonGroup
            color="primary"
            value={timeOfDay}
            exclusive
            onChange={handleTimeChange}
            aria-label="Time of day"
            fullWidth
            sx={{ my: 2 }}
          >
            <ToggleButton value="morning" aria-label="morning">
              Morning
            </ToggleButton>
            <ToggleButton value="afternoon" aria-label="afternoon">
              Afternoon
            </ToggleButton>
          </ToggleButtonGroup>
          {dayIsNotInThePast(selectedDay) && !loading && 
            <Box sx={{ width: '100%' }}>
            {getFreeSpots() < 0 ? (
              <Alert severity="error" sx={{ width: '100%' }} variant="outlined" color="error">
                {`Capacity exceeded. Free up ${getFreeSpots()*-1} seat(s).`}
              </Alert>
            ) : (
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleOpenBookGuest({ day: selectedDay, timeOfDay })}
                disabled={getFreeSpots() <= 0}
                sx={{ 
                  my: 1,
                  textTransform: 'none',
                  fontWeight: 500,
                  padding: "11px"
                }}
              >
                {getFreeSpots() <= 0 ? 'No seats available' : `Reserve a guest seat (${getFreeSpots()} available)`}
              </Button>
            )}
        </Box>
        }
          <ListItem disablePadding sx={{mt: 2, mb: 2}}>
          <>
                <ListItemText primary={""}
                 secondary={waitlistCount > 0 ? (
                  <span 
                    onClick={handleWaitlistClick}
                    style={{ 
                      cursor: 'pointer',
                      textDecoration: 'underline'
                    }}
                  >
                    {`Waitlist: ${waitlistCount}`}
                  </span>
                ) : <span 
                onClick={handleWaitlistClick}
                style={{ 
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                {`Waitlist: 0`}
              </span>}
                 secondaryTypographyProps={{ 
                   color: 'text.secondary',
                   sx: { mt: 0.5 }
                 }}
                  />
                  <Button 
                  variant="outlined"
                  size="small"
                  startIcon={notificationStatus ? <RemoveIcon /> : <AddIcon />}
                  onClick={handleNotificationToggle}
                  disabled={checkingNotification}
                  sx={{ 
                    whiteSpace: 'nowrap',
                    minWidth: 'auto',
                    padding: '4px 8px',
                    fontSize: '0.75rem',
                    textTransform: 'none',
                  }}
                >{notificationStatus ? 'Leave waitlist' : 'Join waitlist'}</Button>
                </>
          </ListItem>
           

            {getGuestsForSelectedDayAndTime().length > 0 && (
            <>
             <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" gutterBottom sx={{ 
                paddingBottom: 1
              }}>
                Guests
              </Typography>
              <List>
              {getGuestsForSelectedDayAndTime().map((guest, index) => (
                   <ListItem key={index} disablePadding sx={{ 
                    paddingBottom: 1,  // Adds 8px of padding to the bottom (1 * theme.spacing)
                    '&:last-child': {  // Removes padding from the last item
                      paddingBottom: 0
                    }
                  }}>
                    <ListItemText primary={guest.name} secondary={guest.bookingNote} secondaryTypographyProps={{ color: 'text.secondary' }} />
                    {currentUser && currentUser.uid === guest.userId && (
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(guest)}>
                          <DeleteIcon /> 
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </>
          )}
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" gutterBottom sx={{ 
                paddingBottom: 1
              }}>
              Regulars
            </Typography>
          <List >
            
            {getBookingsForSelectedDayAndTime().map((person, index) => (
              <ListItem key={index} disablePadding sx={{ 
                paddingBottom: 1,  // Adds 8px of padding to the bottom (1 * theme.spacing)
                '&:last-child': {  // Removes padding from the last item
                  paddingBottom: 0
                }
              }}>
                <ListItemText primary={person.name} />
               {adminPhoneNumbers.indexOf(currentUser?.phoneNumber) >= 0 && currentUser?.uid !== person.userId && <ListItemSecondaryAction>
                    
                    {dayIsNotInThePast(selectedDay) && 
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleFreeUpSlotClick(selectedDay, timeOfDay, person)}
                        sx={{ 
                          whiteSpace: 'nowrap',
                          minWidth: 'auto',
                          padding: '4px 8px',
                          fontSize: '0.75rem'
                        }}
                      >Release seat</Button>
                    }
                  </ListItemSecondaryAction>
                }
                {currentUser && currentUser.uid === person.userId && (
                  <ListItemSecondaryAction>
                    
                    {dayIsNotInThePast(selectedDay) && 
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleFreeUpSlotClick(selectedDay, timeOfDay, person)}
                        sx={{ 
                          whiteSpace: 'nowrap',
                          minWidth: 'auto',
                          padding: '4px 8px',
                          fontSize: '0.75rem'
                        }}
                      >Release seat</Button>
                    }
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
            
          </List>

          {getNotAttendingForSelectedDayAndTime().length > 0 && <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" gutterBottom sx={{ 
              paddingBottom: 1
            }}>
            Not Attending
          </Typography>
          <List>
            {getNotAttendingForSelectedDayAndTime().map((person, index) => (
              <ListItem key={index} disablePadding sx={{ 
                paddingBottom: 1,
                '&:last-child': {
                  paddingBottom: 0
                }
              }}>
                <ListItemText primary={person.name} />
              </ListItem>
            ))}
          </List>
          </>}

          <Divider sx={{ my: 2 }} />
          Total: {getBookingsForSelectedDayAndTime().length + getGuestsForSelectedDayAndTime().length}
          
          
         
        </CardContent>
      </Card>
      
      {isBookGuestOpen && 
      <BookGuest
        open={isBookGuestOpen}
        onClose={handleCloseBookGuest}
        weekStart={selectedWeekString}
        schedule={selectedSchedule}
        onBookingMade={onBookingMade}
      />
  }

<Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} >
          {snackbar.message}
        </Alert>
      </Snackbar>

<Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this guest?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus variant="contained" color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    

      <Dialog
        open={freeUpModalOpen}
        onClose={handleFreeUpCancel}
        aria-labelledby="free-up-dialog-title"
        aria-describedby="free-up-dialog-description"
      >
        <DialogTitle id="free-up-dialog-title">{`Release ${spotToFreeUp?.person?.name}'s seat`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="free-up-dialog-description">
            Do you want to free up the <strong>{spotToFreeUp?.timeOfDay} seat</strong> on <strong>{spotToFreeUp && formatDate(selectedWeekString, spotToFreeUp.day)}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFreeUpCancel}>Cancel</Button>
          <Button onClick={handleFreeUpConfirm} autoFocus variant="contained" color="primary">
            Yes, Free Up
          </Button>
        </DialogActions>
      </Dialog>

      <WaitlistModal 
        isOpen={isWaitlistModalOpen}
        onClose={() => setIsWaitlistModalOpen(false)}
        weekStart={selectedWeekString}
        day={selectedDay}
        timeOfDay={timeOfDay}
        formatDate={formatDateShort}
      />
    </Box>
  );
};

export default WeeklyBookings;